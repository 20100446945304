import { required } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['selectedItems.accountName', 'selectedItems.selectedDate'],
    selectedItems: {
      accountName: {
        required
      },
      selectedDate: {
        required
      }
    }
  }
}
