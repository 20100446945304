import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
// import ProtoBufApi from '@/services/protobuf-api'
// import AuditTrailApi from '@/services/audittrail-api'
// import { GlAccountTypes } from '@/enums'

export default {
  async getEntity(id) {
    const result = await FqApi.get(`/bankundepositedfund?id=${id}`)
    return result.data
  },
  async getEntitySummaries(filter) {
    const serialised = this.serialisebankUndepositedFundFilter(filter)
    const url = `/bankundepositedfund/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialisebankUndepositedFundFilter(filter) {
    const bankUndepositedFundProto = require('../../assets/proto/bankundepositedfund/BankUndepositedFundFilter.proto')
    const filterMessage = bankUndepositedFundProto.BankUndepositedFundFilter.BankUndepositedFundFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async postEntity(entity) {
    return FqApi.post('/bankundepositedfund', entity)
  },
  async getGLBankAccountsDropdownProto(id) {
    const result = await FqApi.get(`/bankundepositedfund/getGlBankAccount?id=${id}`)
    return result.data
  }
}