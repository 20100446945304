import { TableColumn } from '@/classes'

const BankUndepositedFundColumns = [
  // new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Drawer of Cheque', 'remarks', true, 'desc', 'left'),
  new TableColumn('Receipted Date', 'transactionDate', true, 'desc', 'left'),
  new TableColumn('Receipted Amount', 'debit', false, 'desc', 'left'),
  new TableColumn('Paid Amount', 'credit', false, 'asc', 'right'),
  new TableColumn('Cheque No', 'cheque', false, 'asc', 'right')
]
const BankUndepositedFundSummaryColumns = [
  new TableColumn('Drawer of Cheque', 'remarks', true, 'desc', 'left'),
  new TableColumn('Amount', 'debit', false, 'desc', 'right')
]
export { BankUndepositedFundColumns, BankUndepositedFundSummaryColumns }