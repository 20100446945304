<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-9">
      <article class="tile is-child box detail-page-tile">
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          v-if="activeItems.length > 0"
          :columns="columns"
          :page-index="filter.pageIndex"
          :page-size="filter.pageSize"
          :total-rows="totalRows"
          :is-loading="isTableLoading"
          :sort-column="filter.sortColumn"
          :sort-order="filter.sortOrder"
          @pageChanged="onPageChange"
          :is-title-checkable="true"
          title-checkbox-location="front"
          :is-title-checked.sync="isTitleCheck"
          @title-checkbox-changed="titleCheckboxChanged"
          @sort="sort">
          <tr v-for="(item,index) in activeItems"
            :key="item.unclearedId"
            :class="{ 'is-selected' : selectedRow === index }">
            <td class="has-vertical-middle has-text-centered is-content-width">
              <input class="is-checkradio is-box is-primary"
                :class="{ 'has-background-color': item.isChecked }"
                :id="`report${index}`"
                :name="`report${index}`"
                type="checkbox"
                v-model="item.isChecked"
                @click="selectBankUndepositedFund(item, index, item.isChecked)">
              <label :for="`report${index}`"
                data-label-empty />
            </td>
            <td>{{ item.remarks }}</td>
            <td class="has-text-right">
              <input type="text"
                disabled
                class="input"
                :value="$filters.formatDateLocale(item.transactionDate, $userInfo.locale)"
                readonly>
            </td>
            <td>
              <div class="has-text-right">{{ item.debit | formatNumber($userInfo.locale) }}</div>
            </td>
            <td>
              <div class="has-text-right">{{ item.credit | formatNumber($userInfo.locale) }}</div>
            </td>
            <td>
              <div class="has-text-right">{{ item.cheque }}</div>
            </td>
          </tr>
          <template slot="empty">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-36px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </template>
        </bulma-table>
      </article>
    </div>
    <div class="tile is-vertical is-3">

      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="field is-pulled-left">
            <label class="label">Select Banking Date</label>
            <v-date-picker v-model="selectedDate"
              :masks="formats"
              :attributes="attrs"
              :locale="$userInfo.locale"
              @input="addBankDetails()">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="field">
                  <div class="control has-icons-left">
                    <div class="control">
                      <input type="text"
                        class="input"
                        :class="{'is-danger' : $v.selectedItems.selectedDate.$invalid}"
                        placeholder="Banking Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                    <span class="help is-danger"
                      v-if="!$v.selectedItems.selectedDate.required">Banking Date is required.
                    </span>
                  </div>
                </div>
              </template>
            </v-date-picker>
            <label class="label">Select Bank Account</label>
            <div class="select field">
              <select v-model="glBankAccount"
                @change="addBankDetails()"
                style="width: 230px">
                <option v-for="(b) in bankAccount"
                  :key="b.accountId"
                  :value="b.accountNo">
                  {{ b.displayName }}
                </option>
              </select>
            </div>
            <label class="label">Enter Account Name</label>
            <p class="control">
              <input class="input"
                :class="{'is-danger' : $v.selectedItems.accountName.$error}"
                placeholder="Account Name"
                v-model="accountName"
                @input="addBankDetails()">
            </p>
            <span class="help is-danger"
              v-if="!$v.selectedItems.accountName.required">Account Name is required.
            </span>
          </div>
        </article>
      </div>
      <div class="tile is-parent">
        <bank-undeposited-fund-summary class="tile is-child box detail-page-tile"
          :entity="value.selectedFund"
          :value="totalAmount" />
      </div>
    </div>
  </div>
</template>

<script>
import BankUndepositedFundService from './BankUndepositedFundService'
import BankUndepositedFundSummary from './BankUndepositedFundSummary'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import BulmaTable from '@/components/BulmaTable'
import { BankUndepositedFundColumns } from './columns'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'BankUndepositedFundDetail',
  inject: ['$vv'],
  components: {
    BulmaTable,
    BankUndepositedFundSummary
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin],
  props: {
    filter: {
      type: Object
    },
    entity: {
      type: Array
    },
    isSave: {
      type: Boolean
    },
    value: null
  },
  data() {
    return {
      activeItems: [],
      selectedItems: [],
      temp: false,
      totalRows: 0,
      selectAllFunds: [],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      selectedRow: null,
      isTableLoading: false,
      totalAmount: 0.0,
      isTitleCheck: false,
      bankAccount: null,
      glBankAccount: '11030',
      accountName: '',
      selectedDate: '',
      isSelected: false,
      isSelectedAccount: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    columns() {
      return BankUndepositedFundColumns
    }
  },
  watch: {
    entity: function(newVal, oldVal) {
      this.activeItems = _cloneDeep(newVal)
      this.getData()
      this.checkTitleStatus()
    },
    activeItems: {
      handler: function(val, oldVal) {
        // Return the object that changed
        this.checkTitleStatus()
      },
      deep: true
    }
    // selectedDate: function(newVal, oldVal) {
    //   if (newVal) {
    //     for (let i = 0; i < this.selectedItems.length; i++) {
    //       this.selectedItems[i].selectedDate = newVal.toISOString().split('.')[0] + 'Z'
    //     }
    //   } else {
    //     for (let i = 0; i < this.selectedItems.length; i++) {
    //       this.selectedItems[i].selectedDate = ''
    //     }
    //   }
    // }
  },
  async created() {
    this.activeItems = _cloneDeep(this.entity)
    this.getData()
    this.checkTitleStatus()
    await this.getBankAccountListCombo()
    this.selectedDate = new Date()
    this.accountName = this.$userInfo.companyName
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    persistFilter() {
      sessionStorage.setItem(this.filter, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filter))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.$showSpinner()
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.$hideSpinner()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    addBankDetails() {
      this.isSelectedAccount = this.bankAccount.filter(b => b.accountNo === this.glBankAccount)
      this.value.selectedBankAccount = this.glBankAccount
      if (!this.selectedDate) {
        this.value.selectedDate = null
      } else {
        this.value.selectedDate = this.selectedDate.toISOString().split('.')[0] + 'Z'
      }
      this.value.accountName = this.accountName
      this.value.accountNumber = this.isSelectedAccount[0].bankAccount
    },
    checkTitleStatus() {
      let found = this.activeItems.find(id => id.isChecked === false)
      if (found) {
        this.isTitleCheck = false
      } else {
        this.isTitleCheck = true
      }
    },
    //Methods
    titleCheckboxChanged(value) {
      if (value) {
        this.$showSpinner()
        for (let i = 0; i < this.activeItems.length; i++) {
          this.selectBankUndepositedFund(this.activeItems[i], i, false)
        }
        this.activeItems.forEach(i => (i.isChecked = value))
        this.$hideSpinner()
      } else {
        let filter = this.activeItems.filter(id => id.isChecked === true)
        for (let i = 0; i < this.activeItems.length; i++) {
          if (filter.length === this.activeItems.length) {
            this.selectBankUndepositedFund(this.activeItems[i], i, true)
          } else {
            if (!this.activeItems[i].isChecked) {
              this.selectBankUndepositedFund(this.activeItems[i], i, true)
            }
          }
        }
      }
    },
    selectBankUndepositedFund(item, index, isCheck) {
      //When Item is Checked
      if (isCheck === false) {
        let index = this.value.selectedFund
          .map(function(id) {
            return id.unclearedId
          })
          .indexOf(item.unclearedId)
        if (index < 0) {
          let bankFund = {
            debitAmount: 0.0,
            creditAmount: 0.0,
            bsbCustomer: '',
            accountNoCutomer: '',
            bsbInsurer: '',
            accountNoInsurer: '',
            remarks: '',
            chequeNo: '',
            unclearedId: ''
          }
          this.totalAmount = roundAwayFromZero(this.totalAmount + item.debit, 2)
          this.value.totalAmount = this.totalAmount
          bankFund.debitAmount = item.debit
          bankFund.creditAmount = item.credit
          bankFund.remarks = item.remarks
          bankFund.chequeNo = item.cheque
          bankFund.bsbCustomer = item.bsbCustomer
          bankFund.accountNoCutomer = item.accountNoCutomer
          bankFund.bsbInsurer = item.bsbInsurer
          bankFund.accountNoInsurer = item.accountNoInsurer
          bankFund.unclearedId = item.unclearedId

          //Add value to SelectedFund
          this.value.selectedFund.push(bankFund)
          this.value.unclearedIdList.push(item.unclearedId.trim())
          this.value.accountName = this.accountName
          this.value.selectedBankAccount = this.glBankAccount
          this.value.selectedDate = this.selectedDate.toISOString().split('.')[0] + 'Z'
          this.isSelectedAccount = this.bankAccount.filter(b => b.accountNo === this.glBankAccount)
          this.value.accountNumber = this.isSelectedAccount[0].bankAccount
        }
      }
      //When Item is Unchecked
      else {
        //Remove value from SelectedFund
        let index = this.value.selectedFund
          .map(function(id) {
            return id.unclearedId
          })
          .indexOf(item.unclearedId)
        if (index >= 0) {
          this.value.selectedFund.splice(index, 1)
          this.totalAmount = roundAwayFromZero(this.totalAmount - item.debit, 2)
          this.value.totalAmount = this.totalAmount
        }

        //Remove List of UnclearedId
        let index2 = this.value.unclearedIdList.indexOf(item.unclearedId.trim())

        if (index2 >= 0) {
          this.value.unclearedIdList.splice(index2, 1)
        }
        //Untick ActiveItems
        let index3 = this.activeItems
          .map(function(id) {
            return id.unclearedId
          })
          .indexOf(item.unclearedId)

        this.activeItems[index3].isChecked = false
      }
      this.checkTitleStatus()
    },
    getData() {
      if (this.isSave) {
        this.isTableLoading = true
        this.value.selectedFund = []
        this.value.totalAmount = 0.0
        this.value.unclearedIdList = []
        this.totalAmount = 0.0
      }
      // this.activeItems = await BankUndepositedFundService.getEntitySummaries(this.filter)
      this.totalRows = this.activeItems.length > 0 ? this.activeItems[0].totalRows : 0
      if (this.value.selectedFund.length > 0) {
        for (let i = 0; i < this.value.selectedFund.length; i++) {
          let index = 0
          index = this.activeItems
            .map(function(id) {
              return id.unclearedId
            })
            .indexOf(this.value.selectedFund[i].unclearedId)
          if (index >= 0) {
            this.activeItems[index].isChecked = true
          }
        }
      }
      this.isTableLoading = false
      this.$hideSpinner()
    },
    //Getting Bank Account Combo List
    async getBankAccountListCombo() {
      this.bankAccount = await BankUndepositedFundService.getGLBankAccountsDropdownProto(this.$userInfo.companyId)
      let index = this.bankAccount.findIndex(i => i.glCategory === 'UND')
      if (index > -1) {
        this.bankAccount.splice(index, 1)
      }
    }
  }
}
</script>
