<template>
  <article>
    <p class="title">Summary</p>
    <p v-if="value === ''"
      class="subtitle has-text-success"> Banking Amount: $0.00</p>
    <p v-else
      class="subtitle has-text-success"> Banking Amount: ${{ value | formatNumber($userInfo.locale) }}</p>
    <div style="overflow-y: auto; height: 400px;">
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :total-rows="entity.length"
        :show-pagination="false">
        <tr v-for="(item,index) in entity"
          :key="item.unclearedId"
          :class="{ 'is-selected' : selectedRow === index }">
          <td>
            <div class="has-vertical-middle">{{ item.remarks }}</div>
          </td>
          <td>
            <div class="has-text-right">{{ item.debitAmount | formatCurrency($userInfo.locale) }}</div>
          </td>
        </tr>
        <template slot="empty">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-medium">
              <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
            </span>
          </div>
        </template>
        <!-- <tfoot>
          <tr>
            <th class="has-text-left">Banking Total </th>
            <th class="has-text-right has-text-success">{{ value | formatCurrency($userInfo.locale) }}</th>
          </tr>
        </tfoot> -->
      </bulma-table>
    </div>
  </article>
</template>
<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { BankUndepositedFundSummaryColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'BankUndepositedFundSummary',
  components: {
    BulmaTable
  },
  mixins: [NumberFiltersMixin],
  props: {
    entity: null,
    value: null
  },
  data() {
    return {
      selectedRow: null
    }
  },
  computed: {
    columns() {
      return BankUndepositedFundSummaryColumns
    }
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    }
  }
}
</script>
