<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="isSaveButton"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <bank-undeposited-fund-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <router-view
          v-if="entity"
          v-model="selectedItems"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          :filter="filter"
          :entity="entity"
          :is-save="isSave" />
      </div>
    </div>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="saveEntity('ok')"
      @cancel="saveEntity('print')"
      @close="saveEntity('close')"
      :ok-text="'Save & Print'"
      :cancel-text="'Print'"
      :close-text="'Close'"
      :show-close="true">
      <p slot="text-title">Do you want to Save and Print?</p>
      <p slot="text-content">
        This will save your selected Bank Un-deposited Funds and Print.
      </p>
    </confirm-modal>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
  </div>
</template>

<script>
import BankUndepositedFundSideMenu from './BankUndepositedFundSideMenu'
import BankUndepositedFundService from './BankUndepositedFundService'
import BankUndepositedFundRoutes from './route-types'
import BankUndepositedFundValidation from './BankUndepositedFundValidation'
import StoreUtil from '@/store/utils'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import ReceiptEntryRoutes from '../receiptentry/route-types'
import HttpStatus from '@/components/http-status'
import EventBus from '@/components/EventBus'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'
import _debounce from 'lodash.debounce'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'BankUndepositedFundView',
  components: {
    AppDetailHeader,
    [BankUndepositedFundSideMenu.name]: BankUndepositedFundSideMenu,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [StoreMixin, BankUndepositedFundValidation],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      filter: {
        query: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 15
      },
      newEntity: {
        companyId: null,
        selectedDate: new Date(),
        selectedBankAccount: null,
        accountName: null,
        bankingTotalAmount: 0.00,
        unclearedIdList: []
      },
      selectedItems: {
        selectedDate: new Date(),
        selectedBankAccount: null,
        accountNumber: null,
        accountName: null,
        totalAmount: 0.00,
        unclearedIdList: [],
        selectedFund: []
      },
      isTableLoading: false,
      totalRows: 0,
      entity: null,
      toRoute: null, // stores "to" route of beforeRouteLeave
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      isSaveButton: false,
      isConfirmModalActive: false,
      isSave: false
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error || this.$v.detailGroup.$invalid
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          //this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    },
    'selectedItems.selectedFund'(newVal, oldVal) {
      if(newVal) {
        this.isSaveButton = false
        let find = this.selectedItems.selectedFund.length
        if(find > 0)
        {
          this.isSaveButton = true
        }
      }
    },
    'filter.pageIndex'(newVal, oldVal) {
      if(newVal) {
        this.$showSpinner()
        this.getData()
        this.$hideSpinner()
      }
    },
    'filter.sortColumn'(newVal, oldVal) {
      if(newVal) {
        this.getData()
      }
    },
    'filter.sortOrder'(newVal, oldVal) {
      if(newVal) {
        this.getData()
      }
    }
  },
  created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.CompanyId)
    }
    this.getData()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.entity.deleted = true
          this.save(true)
          break
        case AppHeaderButtonTypes.Save:
          this.confirmSave()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getData() {
      this.isTableLoading = true
      // this.retrieveFilter()
      this.entity = await BankUndepositedFundService.getEntitySummaries(this.filter)
      // this.totalRows = this.entity.length > 0 ? this.entity[0].totalRows : 0
      this.isTableLoading = false
      this.$hideSpinner()
    },
    persistFilter() {
      sessionStorage.setItem(this.filter, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filter))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.query = ''
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.filter.pageIndex = 1
      this.filter.pageSize = 15
      this.persistFilter()
      this.getData()
    },
    confirmSave(){
      this.isConfirmModalActive = true
    },

    async saveEntity(confirmSave){
      this.isConfirmModalActive = false
      const title = 'Receipt Entry'
      if(this.validateError){
        this.$notification.openNotificationWithType('danger', title, 'Bank Account or Account Name is Missing')
        return false
      }
      else if(confirmSave === 'ok') {
        // this.print1()
        this.save()
      }
      else if(confirmSave === 'print') {
        this.print1()
      }
    },
    //Save
    async save(isDelete) {
      const title = 'Receipt Entry'
      // this.isConfirmModalActive = true
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }

      try {
        this.$showSpinner('Saving...')
        let response
        //Assign Value to newEntity
        this.newEntity = {
          companyId: this.$userInfo.companyId,
          selectedDate: this.selectedItems.selectedDate,
          selectedBankAccount: this.selectedItems.selectedBankAccount,
          accountName: this.selectedItems.accountName,
          bankingTotalAmount: this.selectedItems.totalAmount,
          unclearedIdList: this.selectedItems.unclearedIdList
        },

        this.saveSnapshot(_cloneDeep(this.newEntity))
        response = await BankUndepositedFundService.postEntity(this.newEntity)
        if (response && response.status === HttpStatus.OK) {
          this.isSave = true
          this.isSaveButton = false
          if(this.isSaveContinue){
            this.isSave = false
            this.isSaveContinue = true
            await this.print1()
          }
          this.print1()
          this.getData()
          // await BankUndepositedFundDetail.getData()
          this.$notification.success('Bank Un-Deposited Funds', isDelete ? 'Bank Undeposited Fund set to In-Active' : 'Save successful')
          // this.$router.push({
          //   name: ReceiptEntryRoutes.ReceiptEntryListView.name
          // })
          this.$hideSpinner()
        }
        else if (response && response.status === HttpStatus.NO_CONTENT) {
          this.$notification.success('Bank Un-Deposited Funds', isDelete ? 'Bank Undeposited Fund set to In-Active' : 'Save successful')
          this.$router.push({
          name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        }
        else if(this.isSkipSave){
          this.$router.push({
            name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        }
        // reloadData()
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        // if (e.response.request.status === HttpStatus.CONFLICT) {
        //   this.isSaveConflictModalActive = true
        // } else {
        if (e.response.status !== HttpStatus.CONFLICT){
          this.$notification.openMessageXhrError('Bank Undeposited Fund', e)
        }

        return false
      } finally {
        this.$hideSpinner()
      }
    },
    cancel() {
      // this.saveSnapshot(_cloneDeep(this.selectedItems))
      if (this.selectedItems.selectedFund.length > 0 && !this.isSkipSave && !this.isSaveContinue) {
        this.isUnsavedModalActive = true
      }
      else if(this.isSkipSave){
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.invoiceId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        }
      }
      else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.invoiceId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: ReceiptEntryRoutes.ReceiptEntryListView.name
          })
        }
      }
    },

    //Save And Continue Function
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSkipSave = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },

    //Print Function
    print1() {
        this.$showSpinner()
        const partsXml = this.generatePartsXml()
        const params = Object.assign({
        ACCOUNTNAME: this.selectedItems.accountName,
        COMPANYID: this.$userInfo.companyId,
        reportDocId: this.$lzstring.compressToBase64(partsXml),
        BANKINGTOTAL: this.selectedItems.totalAmount,
        BANKACCOUNT: this.selectedItems.selectedBankAccount
      })
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()

      emailer.subject = 'Bank Un-Deposited Fund'
      emailer.reportName = 'BankUndepositedList'
      this.addEmailer(emailer)
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)
      const routeData = this.$router.resolve({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_BankUndeposited' },
        query: { parameterId:  parameterId, emailerId: emailer.id, isNewTab: true }
      })
      window.open(routeData.href, '_blank')
      this.$hideSpinner()
      // window.open(printPreviewRoute.href, '_blank')
    },

    //Generate XML for Print
    generatePartsXml() {
      let xmlDoc = document.implementation.createDocument('', '', null)
      let invoiceElement = xmlDoc.createElement('AT_Invoice')
      let bsbField
      let chequeNo
      for (let i = 0; i < this.selectedItems.selectedFund.length; i++) {
        let invoiceItemElement = xmlDoc.createElement('INVOICEITEM')
        let drawerElement = xmlDoc.createElement('Drawer')
        let chequeNoElement = xmlDoc.createElement('ChequeNo')
        let receiptedAmountElement = xmlDoc.createElement('ReceiptedAmount')
        let paidAmountElement = xmlDoc.createElement('PaidAmount')
        let bsbElement = xmlDoc.createElement('BSB')
        let bankAccountElement = xmlDoc.createElement('BankAccountNo')

        let debitAmount = this.selectedItems.selectedFund[i].debitAmount.toString()
        let creditAmount = this.selectedItems.selectedFund[i].creditAmount.toString()
        if(this.selectedItems.selectedFund[i].bsbCustomer || this.selectedItems.selectedFund[i].accountNoCustomer){
          if(this.selectedItems.selectedFund[i].bsbCustomer){
            bsbField = this.selectedItems.selectedFund[i].bsbCustomer
          }
          if(this.selectedItems.selectedFund[i].accountNoCustomer){
            bsbField = this.selectedItems.selectedFund[i].accountNoCustomer
          }
          if(this.selectedItems.selectedFund[i].bsbCustomer && this.selectedItems.selectedFund[i].accountNoCustomer){
            bsbField = this.selectedItems.selectedFund[i].accountNoCustomer + '/' + this.selectedItems.selectedFund[i].bsbCustomer
          }
        }
        if(this.selectedItems.selectedFund[i].bsbInsurer || this.selectedItems.selectedFund[i].accountNoInsurer) {
          if(this.selectedItems.selectedFund[i].bsbInsurer){
            bsbField = this.selectedItems.selectedFund[i].bsbInsurer
          }
          if(this.selectedItems.selectedFund[i].accountNoInsurer){
            bsbField = this.selectedItems.selectedFund[i].accountNoInsurer
          }
          if(this.selectedItems.selectedFund[i].bsbInsurer && this.selectedItems.selectedFund[i].accountNoInsurer){
            bsbField = this.selectedItems.selectedFund[i].accountNoInsurer + '/' + this.selectedItems.selectedFund[i].bsbInsurer
          }
          //bsbField = this.selectedItems.selectedFund[i].accountNoInsurer + '/' + this.selectedItems.selectedFund[i].bsbInsurer
        }
        else{
          bsbField = ''
        }

        if(this.selectedItems.selectedFund[i].chequeNo){
          chequeNo = this.selectedItems.selectedFund[i].chequeNo
        }
        else{
          chequeNo = ''
        }
        drawerElement.innerHTML = this.selectedItems.selectedFund[i].remarks.escapeXml()
        chequeNoElement.innerHTML =chequeNo.escapeXml()
        receiptedAmountElement.innerHTML = debitAmount.escapeXml()
        if(roundAwayFromZero(creditAmount, 2) > 0) {
          paidAmountElement.innerHTML = creditAmount.escapeXml()
        }
        else {
          creditAmount = '0.00'
          paidAmountElement.innerHTML = creditAmount.escapeXml()
        }
        bsbElement.innerHTML = bsbField.escapeXml()
        bankAccountElement.innerHTML = this.selectedItems.accountNumber.escapeXml()

        invoiceItemElement.appendChild(drawerElement)
        invoiceItemElement.appendChild(chequeNoElement)
        invoiceItemElement.appendChild(receiptedAmountElement)
        invoiceItemElement.appendChild(paidAmountElement)
        invoiceItemElement.appendChild(bsbElement)
        invoiceItemElement.appendChild(bankAccountElement)
        invoiceElement.appendChild(invoiceItemElement)
      }
      xmlDoc.appendChild(invoiceElement)
      return new XMLSerializer().serializeToString(xmlDoc)
    },

    //Skip Button
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },

    //Redirect Route
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },

    //Close Modal
    closeModal() {
      this.isUnsavedModalActive = false
      // this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },

    //Reload Data
    async reloadData() {
      // this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|bankundepositedfund|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|bankundepositedfund|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|bankundepositedfund|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|bankundepositedfund|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: BankUndepositedFundRoutes.BankUndepositedFundDetail.name,
        params: { CompanyId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.selectedItems))
    if (this.selectedItems.selectedFund.length > 0 && !this.isSkipSave && !this.isSaveContinue && !this.isSave) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.assetID)
      this.clearSessionStorage()
      this.clearRouteMeta()
      next()
    }
  }
}
</script>